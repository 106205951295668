import React, { useContext,lazy } from 'react';


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { ToastContainer } from './utils/toast';
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer';
import PrivateRoute from './components/login/PrivateRoute';
import { AdminContext } from "context/AdminContext";
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'react-polyfill-patch';



const Layout = lazy(() => import('./layout/Layout'));
// const SLayout = lazy(() => import('./layout/SLayout'));
const WebQuiz = lazy(() => import('./pages/WebQuiz'));
const Login = lazy(() => import('./pages/Login'));
const AdminLogin = lazy(()=>import('./pages/AdminLogin'));
const SignUp = lazy(() => import('./pages/SignUp'));
const Application = lazy(() => import('./pages/Application'));
const Application2 = lazy(() => import('./pages/Application2'));
const Personal = lazy(() => import('./pages/Personal'));
const PersonalNew = lazy(() => import('./pages/PersonalPage'));
const Personal2 = lazy(() => import('./pages/Personal2'));
const Showlection = lazy(() => import('./pages/Showlection'));
const Showlection2 = lazy(() => import('./pages/Showlection2'));

const ZoomCon = lazy(() => import('./pages/ZoomCon'));
const ForgetPassword = lazy(() => import('./pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const  Complete = lazy(()=> import('./pages/Complete'));
const  Close = lazy(()=> import('./pages/Close'));

const App = () => {

 
  const { state } = useContext(AdminContext);

  return (

    <>
      <ToastContainer />
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          {/* <Route path="/login" component={Login} /> */}
          <Route path="/adminlogin" component={AdminLogin} />
          <Route path="/signup" component={SignUp} />
          <Route path="/application" component={Application} />
          <Route path="/application2" component={Application2} />
          <Route path="/complete" component={Complete} />
          <Route path="/close" component={Close} />
          {/* <Route path="/web/web_main" component={Personal} /> */}
        <Route path="/web/web_main" component={PersonalNew} /> 
          <Route path="/web/pre" component={Personal2} />
          <Route path="/web/lection" component={Showlection} />
          <Route path="/web/prelection" component={Showlection2} />
          <Route path="/web/quiz" component={WebQuiz} />

          
          <Route path="/zoomcon/:id" component={ZoomCon} />
          <Route path="/forgot-password" component={ForgetPassword} />
          <Route path="/reset-password" component={ResetPassword} />

            <PrivateRoute>
            
            
            <Route path="/" component={Layout} />
            
          </PrivateRoute>
          <Redirect exact from="/adminlogin" to="/adminlogin" />
      
          
        </Switch>
      </Router>
    </>
  );
};

export default App;
